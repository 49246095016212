import React from 'react';
import './styles/text-block.css';

export default function(props) {
  let textBlockClasses = 'text-block';

  if (props.whiteText) {
    textBlockClasses += ' white-text';
  }

  if (props.customClass) {
    textBlockClasses += ' ' + props.customClass;
  }

  return (
    <div className={textBlockClasses}>
      {props.children}
    </div>
  );
}
