import React from 'react';
import './styles/heading.css';

export default function(props) {
  let containerClasses = 'page-heading';

  if (props.dark) {
    containerClasses += ' page-heading--dark';
  }

  return (
    <div className={containerClasses}>
      <h1 className="heading-bold">
        {props.text}
        <span className="page-heading__links">
          {props.children}
        </span>
      </h1>
    </div>
  );
}
