import React from 'react';
import classnames from 'classnames';
import NavMenuImage from './NavMenuImage';
import { withRouter } from 'react-router-dom';
import disableScroll from 'disable-scroll';
import browser from 'detect-browser';

import logo_white_green from '../../assets/images/logo_white_green.svg';
import logo_grey_white from '../../assets/images/logo_grey_white.svg';
import logo_black_green from '../../assets/images/logo_black_green.svg';

import './styles/nav-menu.css';

class NavMenu extends React.Component {
  state = {
    touchMove: false,
    menuOpen: false,
    darkMenu: false
  }

  componentDidMount() {
    window.addEventListener('click', this.handlePageClick);
    this.props.history.listen(() => {
      if (this.state.menuOpen) {
        this.closeMenu();
      }
    });

    this.determineDarkMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.determineDarkMenu();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handlePageClick);
  }

  determineDarkMenu() {
    if (!this.state.darkMenu &&
      (document.querySelector('.page-not-found') ||
      window.location.pathname === '/about-us')
    ) {
      this.setState({darkMenu: true})
    } else if (this.state.darkMenu) {
      this.setState({darkMenu: false})
    }
  }

  handleTouchMove = () => {
    if (!this.state.touchMove) {
      this.setState({touchMove: true});
    }
  }

  toggleMenu = () => {
    if (this.state.touchMove) {
      return;
    }

    if (!this.state.menuOpen) {
      this.openMenu();
    } else {
      this.closeMenu();
    }
  }

  handleClickMenu = (e) => {
    // Let the on touch handler handle the mobile screen sizes
    if (this.isMobile()) {
      e.preventDefault();
      return;
    }

    this.toggleMenu();
  }

  openMenu() {
    this.setState({menuOpen: true}, () => disableScroll.on())
  }

  closeMenu() {
    this.setState({menuOpen: false}, () => disableScroll.off());
  }

  handlePageClick = (e) => {
    if (!this.navMenu.contains(e.target) &&
        e.target.className.indexOf('hamburger') === -1
    ) {
      this.closeMenu();
    }
  }

  isMobile() {
    let isMobile = false;
    let browserDetected;

    if (browser) {
      browserDetected = browser.detect();
    }

    if (browserDetected && (browserDetected.os === 'iOS' || browserDetected.os === 'Android OS')) {
      isMobile = true;
    }

    return isMobile;
  }

  render() {
    let logoImg = logo_white_green;
    const {darkMenu} = this.state;
    const hamburgerClasses = classnames({
      'hamburger': true,
      'hamburger--slider': true,
      'is-active': this.state.menuOpen
    });
    const navClasses = classnames({
      'nav-menu': true,
      'nav-menu--top': this.props.topMenu,
      'nav-menu--open': this.state.menuOpen,
      'nav-menu--dark': darkMenu
    });
    const navIconClasses = classnames({
      'nav-menu-icons': true,
      'nav-menu-icons--dark': darkMenu
    });
    const overlayClasses = classnames({
      'nav-menu-overlay': true,
      'nav-menu-overlay--show': this.state.menuOpen,
    });

    if (window.location.pathname === '/why-ev') {
      logoImg = logo_grey_white;
    } else if (darkMenu) {
      logoImg = logo_black_green;
    }

    return (
      <nav className="nav-menu-container">
        <div className={navIconClasses}>
          <NavMenuImage
            route="/"
            src={logoImg}
            alt='Logo'
          />

          <button
            className={hamburgerClasses}
            type="button"
            onTouchMove={this.handleTouchMove}
            onTouchEnd={this.toggleMenu}
            onClick={this.handleClickMenu}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>

        <NavMenuImage
          route="/"
          src={logoImg}
          alt='Logo'
        />

        <div className={navClasses} ref={node => this.navMenu = node}>
          {this.props.children}
        </div>
        <div className={overlayClasses} />
      </nav>
    );
  }
}

export default withRouter(NavMenu);
