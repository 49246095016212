import React from 'react';
import {Link} from 'react-router-dom';
import Page from '../Page';
import PageSection from '../PageComponents/PageSection';
import HeroImage from '../Widgets/HeroImage';
import PageHeading from '../Widgets/PageHeading';
import SecondaryHeading from '../Widgets/SecondaryHeading';
import TextBlock from '../Widgets/TextBlock';
import BackgroundImageBlock from '../Widgets/BackgroundImageBlock';
import ImageBlock from '../Widgets/ImageBlock';
import ImageHeader from '../Widgets/ImageHeader';
import DealerLogos from '../Widgets/DealerLogos';
import isMobile from '../../utils/isMobile';

import './styles/why-ev.css';

import girls_in_car_desktop from '../../assets/images/why-ev/girls_in_car_desktop.jpg';
import girls_in_car_mobile from '../../assets/images/why-ev/girls_in_car_mobile.jpg';
import ev_icons from '../../assets/images/ev_icons.svg';
import forth_drive_finder from '../../assets/images/why-ev/forth_drive_finder.jpg';
import battery_status from '../../assets/images/battery_status.svg';
import white_nissan_leaf from '../../assets/images/why-ev/white_nissan_leaf.jpg';

let imageHeights = {
  driveFinder: {
    desktop: '890px',
    mobile: '500px'
  },
  leaf: {
    desktop: '774px',
    mobile: '474px'
  }
};

export default function() {
  document.title = 'Live Electric | Why EV?';

  return (
    <Page className="why-ev">
      <PageSection customClass="main-section">
        <HeroImage
          desktopSrc={girls_in_car_desktop}
          mobileSrc={girls_in_car_mobile}
          alt="Two girls looking out the window of a car giving the peace sign"
        >
          <PageHeading text="WHY EV?" />
          <SecondaryHeading hideHR white>
            <span className="heading-light">A Few Good Reasons to</span>
            <br />
            GO ELECTRIC
        </SecondaryHeading>
        </HeroImage>
      </PageSection>

      <PageSection customClass="intro">
        <SecondaryHeading hideHR green>
          Is an Electric Vehicle Right for Me? <br /> <a className="black-text" href="https://rockymountainpower.wattplan.com/ev/">Start My Estimate.</a>
        </SecondaryHeading>
        <TextBlock customClass="intro-text">
          <p>
            Thought about how much gas you're wasting running errands in that Toyota 4Runner or Subaru Outback? Ever looked into purchasing an electric vehicle, but thought it looked too expensive or inconvenient?
          </p>
          <p>
            With significantly lower fuel and maintenance costs, zero emissions, manufacturer incentives, and federal and state tax breaks, it makes sense to buy an EV, now. Here's the why and how:
          </p>
        </TextBlock>
      </PageSection>

      <PageSection customClass="reasons-why">
        <ImageHeader
          bgSrc={ev_icons}
          imgClass="ev-icon ev-icon--gear"
        >
          Lose Your Mechanics Number
        </ImageHeader>
        <TextBlock>
          <p>
            It's basic physics. Fewer moving parts equals less maintenance. By avoiding scheduled maintenance and operating costs, EV drivers are likely to save  as much as $10,000 compared to an internal combustion vehicle.
          </p>
        </TextBlock>
        <ImageHeader
          bgSrc={ev_icons}
          imgClass="ev-icon ev-icon--pump"
        >
          Spend Zip at the Pump
        </ImageHeader>
        <TextBlock>
          <p>
            Annually, the average American household spends $2,512 on gas.
          </p>
          <p>
            Comparatively, it costs about $540 per year to charge an all-electric vehicle. Plus, you can choose from pricing
            options and access incentives for home charging.
          </p>
        </TextBlock>
        <ImageHeader
          bgSrc={ev_icons}
          imgClass="ev-icon ev-icon--car-plug"
        >
          Is This Thing Even On?
        </ImageHeader>
        <TextBlock>
          <p>
            They're crazy fun to drive. If you've never been in an EV, you're in for a jolt. With power going directly to the tires, acceleration is awesome. And you'll also likely turn your EV on and off a couple times at first because they're virtually silent.
          </p>
        </TextBlock>
        <ImageHeader
          bgSrc={ev_icons}
          imgClass="ev-icon ev-icon--battery"
        >
          High-Watt Incentives
        </ImageHeader>
        <TextBlock>
          <p>
            Manufacturers, federal, state, and local governments, all want to make Living Electric cheap and easy. There are a number of money-saving incentives many, of which are featured below.
          </p>
          <p>
            (Also EVs are often given VIP parking opportunities.)
          </p>
        </TextBlock>
        <ImageHeader
          bgSrc={ev_icons}
          imgClass="ev-icon ev-icon--globe"
        >
          Be An Electric Citizen
        </ImageHeader>
        <TextBlock>
          <p>
            We could reduce our dependence on foreign oil by 30-60% if we transitioned all light-duty vehicles to EVs. Also, Living Electric significantly benefits the airshed. EVs powered by electricity from the local grid currently produce 54 percent less (lifetime) carbon pollution than gasoline cars.
          </p>
        </TextBlock>
      </PageSection>

      <PageSection customClass="find-ride">
        <BackgroundImageBlock
          src={forth_drive_finder}
          blockHeight={isMobile() ? imageHeights.driveFinder.mobile : imageHeights.driveFinder.desktop}
        >
          <SecondaryHeading white hideHR>
            Your EV Soulmate:<br />
            Find the Right Ride for You
          </SecondaryHeading>
          <TextBlock>
            <p>
              Thanks to the sharp folks at Forth Mobility, now you can answer a few questions about where you drive and live and they'll help you find the perfect vehicle for you.
            </p>
            <p>
              <a href="https://forthmobility.org/drive-finder" target="_blank" rel="noopener noreferrer">Click here to find your new electric ride.</a>
            </p>
          </TextBlock>
        </BackgroundImageBlock>
      </PageSection>

      <PageSection customClass="juice">
        <SecondaryHeading>
          FIND YOUR JUICE
        </SecondaryHeading>
        <TextBlock>
          <p>
              Home charging. Workplace charging. Charging stations are all around the valley and along major highway corridors so you can explore our national parks and destinations.
          </p>
          <p>
            You’ll be surprised how many convenient <Link to="/electric-highways">charging points</Link> are available to fit your daily transportation routine.
          </p>
        </TextBlock>
        <ImageBlock src={battery_status} alt="Battery statuses" />
      </PageSection>

      <PageSection customClass="basics">
        <BackgroundImageBlock
          src={white_nissan_leaf}
          blockHeight={isMobile() ? imageHeights.leaf.mobile : imageHeights.leaf.desktop}
        >
          <SecondaryHeading white>
            ELECTRIC VEHICLES: THE BASICS
          </SecondaryHeading>
          <TextBlock whiteText>
            <p>
              If you're still not sure about what it means to own an electric vehicle, there are a number of <a href="http://electricvehicles.energy.gov/" target="_blank" rel="noopener noreferrer">great resources</a> to help you understand the differences between types of chargers, lengths of time required to replenish a battery, range concerns, and to plan your new electric life in Utah.
            </p>
          </TextBlock>
        </BackgroundImageBlock>
      </PageSection>

      <PageSection customClass="where-buy">
        <SecondaryHeading>
          WHERE DO I BUY AN EV IN UTAH?
        </SecondaryHeading>
        <TextBlock>
          <p>
            Almost every major manufacturer currently offers an electric vehicle model.
          </p>
          <p>
            Click on a logo to find a dealer near you.
          </p>
        </TextBlock>
        <DealerLogos />
        <hr className="faded-hr" />
      </PageSection>
    </Page>
  );
}
