import React, { Component } from 'react'
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";

import markers from './data/map_points.js';

import map_pin_white from '../../assets/images/map_pin_white.svg';
import map_pin_grey from '../../assets/images/map_pin_grey.svg';
import map_pin_gold from '../../assets/images/map_pin_gold.svg';


const mapStyles = [
  {elementType: 'geometry', stylers: [{color: '#d2ccc6'}]},
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{color: '#a99b78'}]
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text.fill',
    stylers: [{color: '#a38268'}]
  },
  {
    featureType: 'administrative.province',
    elementType: 'geometry.stroke',
    stylers: [{color: '#7d605a'}]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{color: '#a39674'}]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{color: '#c2bf9a'}]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{color: '#6b9a76'}]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{color: '#e1dcd9'}]
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{color: '#e1dcd9'}]
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{color: '#9c9792'}]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{color: '#e7c49c'}]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{color: '#d6b273'}]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{color: '#f3d19c'}]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{color: '#acb2cb'}]
  },
];

const infoWindowStyles = {
  type: {
    fontSize: '16px',
    fontFamily: 'Helvetica,Arial,sans-serif',
    color: '#414042',
    marginBottom: '10px',
    fontWeight: 'bold'
  },
  name: {
    fontSize: '14px',
    fontFamily: 'Helvetica,Arial,sans-serif',
    lineHeight: 1.4,
    color: '#414042',
    marginBottom: '6px'
  },
  info: {
    fontSize: '14px',
    fontFamily: 'Helvetica,Arial,sans-serif',
  }
};


const GettingStartedGoogleMap = withGoogleMap(props => (
  <GoogleMap
    ref={props.onMapLoad}
    defaultZoom={7}
    defaultCenter={{ lat: 40.758701, lng: -111.876183 }}
    onClick={props.onMapClick}
    defaultOptions={{ styles: mapStyles }}
  >
    {props.markers.map((marker, index) => (
      <Marker
        key={marker.name + index}
        position={{lat: marker.geo.lat, lng: marker.geo.lng}}
        title={marker.name}
        showInfo={false}
        onClick={props.onMarkerClick.bind(null, index)}
        icon={{
          url: (marker.type === 'Charging Station') ?
            map_pin_grey : (marker.type === 'DC Fast Charging Station') ?
              map_pin_gold : map_pin_white,
          size: {width: 30, height: 42},
          anchor: {x: 15, y: 42}
        }}
      >
        {marker.showInfo && (
          <InfoWindow>
            <div>
              <div className="marker-type" style={infoWindowStyles.type}>
                {marker.type}
              </div>
              {marker.name === 'N/A' ? '' : (
                <div className="marker-name" style={infoWindowStyles.name}>
                  {marker.name}
                </div>
              )}
              {marker.url && (
                <div className="marker-info" style={infoWindowStyles.info}>
                  <a href={marker.url} target="_blank" rel="noopener noreferrer">View Website</a>
                </div>
              )}
              {marker.address && (
                <div className="marker-info" style={infoWindowStyles.info}>
                  {marker.address}
                </div>
              )}
            </div>
          </InfoWindow>
        )}
      </Marker>
    ))}
  </GoogleMap>
));

class Map extends Component {
  state = { markers }
  handleMarkerClick = (targetMarkerIndex) => {
    this.setState({
      markers: this.state.markers.map(marker => {
        if (marker === this.state.markers[targetMarkerIndex]) {
          return {
            ...marker,
            showInfo: true,
          };
        } else if (marker.showInfo === true) {
          return {
            ...marker,
            showInfo: false,
          };
        }

        return marker;
      }),
    });
  }

  render () {
    return (
      <div className="map">
        <GettingStartedGoogleMap
          containerElement={
            <div style={{ height: `100%` }} />
          }
          mapElement={
            <div style={{ height: `100%` }} />
          }
          markers={this.state.markers}
          onMarkerClick={this.handleMarkerClick}
        />
      </div>
    )
  }
}

export default Map
