import React from 'react';

import './styles/dealer-logos.css';
import dealer_icons from '../../assets/images/why-ev/dealer_icons.png';

const dealerInfo = [
  {
    displayName: 'BMW',
    class: 'dealer-icon--bmw',
    url: 'https://www.bmwusa.com/dealer.html'
  },
  {
    displayName: 'Cadillac',
    class: 'dealer-icon--cadillac',
    url: 'https://www.cadillac.com/luxury-dealer-locator.html'
  },
  {
    displayName: 'Chevrolet',
    class: 'dealer-icon--chevrolet',
    url: 'https://www.chevrolet.com/'
  },
  {
    displayName: 'Fiat',
    class: 'dealer-icon--fiat',
    url: 'https://www.fiatusa.com/en/find-dealer/'
  },
  {
    displayName: 'Ford',
    class: 'dealer-icon--ford',
    url: 'https://www.ford.com/'
  },
  {
    displayName: 'Hyundai',
    class: 'dealer-icon--hyundai',
    url: 'https://www.hyundaiusa.com/dealer-locator/'
  },
  {
    displayName: 'KIA',
    class: 'dealer-icon--kia',
    url: 'https://www.kia.com/us/en/vehicle/hybrid-electric-cars'
  },
  {
    displayName: 'Mercedes',
    class: 'dealer-icon--mercedes',
    url: 'https://www.mbusa.com/mercedes/vehicles/class/class-B'
  },
  {
    displayName: 'Mitsubishi',
    class: 'dealer-icon--mitsubishi',
    url: 'https://www.mitsubishicars.com/dealers'
  },
  {
    displayName: 'Nissan',
    class: 'dealer-icon--nissan',
    url: 'https://www.nissanusa.com/nissandealers/location/utah?language=en'
  },
  {
    displayName: 'Tesla',
    class: 'dealer-icon--tesla',
    url: 'https://www.tesla.com/'
  },
];

function DealerLogos() {
  const iconStyles = {
    backgroundImage: `url(${dealer_icons})`,
    backgroundRepeat: 'no-repeat'
  };

  return (
    <div className="dealer-logos">
      {dealerInfo.map((dealer, index) => (
        <div className="dealer-logos__logo-wrapper" key={dealer.displayName}>
          <p
            className={`dealer-title dealer-title--${dealer.displayName.toLowerCase()}`}
          >
            {dealer.displayName}
          </p>
          <a href={dealer.url} title={dealer.displayName} target="_blank" rel="noopener noreferrer">
            <div className={`dealer-icon ${dealer.class}`} style={iconStyles} />
          </a>
        </div>
      ))}
    </div>
  );
}

export default DealerLogos;
