import React from 'react';
import './styles/heading.css';

export default function(props) {
  let blockClass = 'secondary-heading';
  let hrClass = 'faded-hr';

  if (props.customClass) {
    blockClass += ` ${props.customClass}`;
  }

  if (props.white) {
    blockClass += ' white-text';
    hrClass += ' faded-hr--white';
  }

  if (props.green) {
    blockClass += ' green-text';
  }

  if (props.hideHR) {
    hrClass += ' faded-hr--hide';
  }

  return (
    <div className={blockClass}>
      <h2 className="heading-bold">
        {props.children}
      </h2>
      <hr className={hrClass} />
    </div>
  );
}
