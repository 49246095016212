import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

class NavMenuItem extends React.Component {
  static propTypes = {
    children: PropTypes.string.isRequired,
    route: PropTypes.string,
  }

  render() {
    let item = '';

    if (this.props.route) {
      item = (
        <Link to={this.props.route}>
          {this.props.children}
        </Link>
      );
    } else {
      item = this.props.children
    }

    return (
      <div className="nav-menu__item">
        {item}
      </div>
    );
  }
}

export default NavMenuItem;
