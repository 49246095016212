import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

class NavMenuImage extends React.Component {
  static propTypes = {
    route: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string
  }

  static defaultProps = {
    alt: ''
  };

  render() {
    return (
      <div className="nav-menu__image">
        <Link to={this.props.route}>
          <img src={this.props.src} alt={this.props.alt} />
        </Link>
      </div>
    );
  }
}

export default NavMenuImage;
