import React from 'react';
import Page from '../Page';
import PageSection from '../PageComponents/PageSection';
import HeroImage from '../Widgets/HeroImage';
import PageHeading from '../Widgets/PageHeading';
import SecondaryHeading from '../Widgets/SecondaryHeading';
import TextBlock from '../Widgets/TextBlock';
import ImageBlock from '../Widgets/ImageBlock';

import './styles/ev-workplace.css';

import ev_workplace_desktop from '../../assets/images/ev-workplace/ev_workplace_desktop.jpg';
import ev_workplace_mobile from '../../assets/images/ev-workplace/ev_workplace_mobile.jpg';
import battery_status from '../../assets/images/battery_status.svg';
import ev_icons from '../../assets/images/ev_icons.svg';
import utah_clean_cities from '../../assets/images/utah_clean_cities.png';
import breathe_utah from '../../assets/images/breathe_utah.png';
import leaders_for_clean_air from '../../assets/images/leaders_for_clean_air.png';
import forth_mobility from '../../assets/images/forth_mobility.jpg';

export default function() {
  document.title = 'Live Electric | EV Workplace';

  return (
    <Page className="ev-workplace">
      <PageSection customClass="main-section">
        <HeroImage
          desktopSrc={ev_workplace_desktop}
          mobileSrc={ev_workplace_mobile}
          alt="Desk with books, glasses and a computer on it"
        >
          <PageHeading text="EV WORKPLACE" />
          <SecondaryHeading hideHR white>
            A GREEN FLEET BUSINESS IS PREPARED<br />
            FOR THE NEXT PHASE OF THE ECONOMY
        </SecondaryHeading>
        </HeroImage>
      </PageSection>

      <PageSection customClass="intro">
        <SecondaryHeading hideHR>
          A GREEN FLEET BUSINESS IS PREPARED<br />
          FOR THE NEXT PHASE OF THE ECONOMY
        </SecondaryHeading>
        <TextBlock customClass="intro-text">
          <p>
            Think about how much it costs your business when gas prices spike.
          </p>
          <p>
            Investing capital in low maintenance electric vehicles is more than a lofty ideal. <a href="https://utahcleancities.org/" target="_blank" rel="noopener noreferrer" title="Utah Clean Cities">Utah Clean Cities</a> is here to help you start reducing the dependence on fossil fuels that exposes you to economic risk.
          </p>
          <p>
            The decision to start implementing clean strategies helps your employees feel that their company is committed to making Utah a healthier place to live. Utah Clean Cities is here to help you make the switch to cleaner-fueled vehicles.
          </p>
        </TextBlock>
        <div className="ev-icon ev-icon--leaf-light" style={{backgroundImage: `url(${ev_icons})`}} />
        <hr className="faded-hr" />
      </PageSection>

      <PageSection customClass="free-chargers">
        <SecondaryHeading hideHR green>
          WORKPLACE CHARGERS<br/>
          FOR YOUR BUSINESS
        </SecondaryHeading>
        <TextBlock>
          <p>
            When workplace charging is available, employees are six times more likely to purchase an electric vehicle. <a href="https://www.rockymountainpower.net/env/ev/tcb.html" target="_blank" rel="noopener noreferrer" title="Rocky Mountain Power">Rocky Mountain Power</a> is making workplace EV charging a simple, cost-effective reality for your business.  This forward-thinking decision encourages your employees and your patrons to choose an EV for their next car.
            Best of all, now you can get your EV chargers at outstanding prices.
          </p>
          <p>
            <a href="https://www.rockymountainpower.net/env/ev/tcb.html" target="_blank" rel="noopener noreferrer" title="Electric Vehicle Incentives">Click now</a> to see what incentives, rebates and deals are right for your business.
          </p>
        </TextBlock>
        <ImageBlock src={battery_status} alt="Battery status icons" />
        <hr className="faded-hr" />
      </PageSection>

      <PageSection customClass="discover">
        <SecondaryHeading hideHR>
          DISCOVER UTAH'S CLEAN-AIR CONSULTING<br />
          HORSEPOWER: FIND YOUR PARTNERS
        </SecondaryHeading>
        <TextBlock>
          <p>
            We're here to help your company Live Electric. There are a number of Utah organizations that exist solely to guide your business through the current grants and incentives out there. Connect with these partners to see how workplace charging, and transitioning to a green fleet is a smart move for the future of your business or municipality:
          </p>
        </TextBlock>
        <div className="partner-logos">
          <a href="https://breatheutah.org" className="breathe-utah" target="_blank" rel="noopener noreferrer" title="Breathe Utah">
            <img src={breathe_utah} alt="Breathe Utah" />
          </a>
          <a href="https://utahcleancities.org/" className="clean-cities" target="_blank" rel="noopener noreferrer" title="Utah Clean Cities">
            <img src={utah_clean_cities} alt="Utah Clean Cities" />
          </a>
          <a href="http://leadersforcleanair.org/" className="leaders" target="_blank" rel="noopener noreferrer" title="Leaders for Clean Air">
            <img src={leaders_for_clean_air} alt="Leaders for clean air" />
          </a>
          <a href="https://forthmobility.org/drive-finder" className="forth-mobility" target="_blank" rel="noopener noreferrer" title="Forth Mobility">
            <img src={forth_mobility} alt="Forth Mobility" />
          </a>
        </div>
        <hr className="faded-hr" />
      </PageSection>
    </Page>
  );
}
