import React from 'react';
import ImageBlock from '../Widgets/ImageBlock';
import SecondaryHeading from '../Widgets/SecondaryHeading';
import SocialLinks from '../Widgets/SocialLinks';
import NavMenu from './NavMenu';
import NavMenuItem from './NavMenuItem';

import './styles/footer.css';
import logo_black_green from '../../assets/images/logo_black_green.svg';

// @TODO add social media buttons and style the navigation menu
export default function() {
  const copySymbol = '\u00A9';
  const currentDate = new Date();

  /**
   *
   * <NavMenuItem route="">
          About Us
        </NavMenuItem>
        <NavMenuItem route="">
          Updates
        </NavMenuItem>
        <NavMenuItem route="">
          Privacy
        </NavMenuItem>
   */
  // NOTE: /updates link needs to be regular HTML so the router doesn't catch it
  return (
    <div className="footer">
      <ImageBlock
        src={logo_black_green}
        alt="Logo"
        imgWidth="110px"
      />
      <SecondaryHeading>
        <span className="green-text">LIVE</span> ELECTRIC
      </SecondaryHeading>
      <SocialLinks />
      <NavMenu>
        <NavMenuItem route="/about-us">
          About
        </NavMenuItem>
        <div className="nav-menu__item">
          <a href="/updates">Updates</a>
        </div>
        <NavMenuItem route="" textOnly>
          {`Live Electric ${copySymbol} ${currentDate.getFullYear()}`}
        </NavMenuItem>
      </NavMenu>

    </div>
  );
}
