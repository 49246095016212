import React from 'react';
import PropTypes from 'prop-types';

import './styles/image-header.css';

class ImageHeader extends React.Component {
  static propTypes = {
    bgSrc: PropTypes.string,
    imgClass: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    imgHeight: PropTypes.string,
    imgWidth: PropTypes.string,
    children: PropTypes.any
  };

  static defaultProps = {
    src: null,
    bgSrc: null,
    imgClass: '',
    alt: '',
    imgHeight: null,
    imgWidth: null
  };

  render() {
    const imgStyle = {};
    let imgClass = 'image-header__image'
    let image;

    if (this.props.imgHeight) {
      imgStyle.height = this.props.imgHeight;
    }

    if (this.props.imgWidth) {
      imgStyle.width = this.props.imgWidth;
    }

    if (this.props.imgClass) {
      imgClass += ` ${this.props.imgClass}`;
    }

    if (this.props.bgSrc) {
      imgStyle.backgroundImage = `url(${this.props.bgSrc})`;

      image = (
        <div className={imgClass} style={imgStyle} />
      );
    } else {
      image = (
        <img
          src={this.props.src}
          alt={this.props.alt}
          style={imgStyle}
          className={imgClass}
        />
      )
    }

    return (
      <div className="image-header">
        {image}
        <h3 className="heading-bold">
          {this.props.children}
        </h3>
      </div>
    );
  }
}

export default ImageHeader;
