import React from 'react';
import PropTypes from 'prop-types';

import './styles/hero-image.css';

class HeroImage extends React.Component {
  static propTypes = {
    // This component needs to wrap the others that go with the image
    // in order to get the desired effect
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    desktopSrc: PropTypes.string.isRequired,
    mobileSrc: PropTypes.string.isRequired,
    alt: PropTypes.string,
  };

  render() {
    const {desktopSrc, mobileSrc, alt, children} = this.props;

    return (
      <div className="hero-image">
        <div className="hero-image__image">
          <picture>
            <source media="(max-width: 640px)" srcSet={mobileSrc} />
            <source media="(min-width: 641px)" srcSet={desktopSrc} />
            <img srcSet={desktopSrc} alt={alt} />
          </picture>
        </div>
        <div className="hero-image__content">
          {children}
        </div>
      </div>
    );
  }
}

export default HeroImage;
