import React from 'react';
import PropTypes from 'prop-types';
import isMobile from '../../utils/isMobile';

class FullPageVideo extends React.Component {
  static propTypes = {
    // This component needs to wrap the others that go with the image
    // in order to get the desired effect
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    src: PropTypes.string.isRequired,
    desktopVidSrc: PropTypes.string.isRequired,
    mobileVidSrc: PropTypes.string.isRequired
  };

  state = {
    screenHeight: window.innerHeight,
    leftPosition: 0
  }

  componentDidMount() {
    if (isMobile()) {
      window.addEventListener(
        'orientationchange',
        () => setTimeout(this.setVideoStyleVals, 100)
      );
    } else {
      window.addEventListener(
        'resize',
        () => setTimeout(this.setVideoStyleVals, 100)
      );
    }
  }

  setVideoStyleVals = () => {
    this.setState({
      screenHeight: window.innerHeight
    }, () => {
      const videoWidth = this.videoEl.getBoundingClientRect().width;
      const windowWidth = window.innerWidth;
      let leftPosition = 0;

      if (windowWidth < videoWidth) {
        // Calculate how much the video should shift so that its center is in
        // the center of the screen
        leftPosition = videoWidth / 2 - windowWidth / 2;
      }
      this.setState({leftPosition});
    });
  }

  render() {
    const {desktopVidSrc, mobileVidSrc, children} = this.props;
    const imageStyle = {
      position: 'relative',
      height: (this.state.screenHeight - 62) || '780px',
      background: `url(${this.props.src}) no-repeat center center`,
      backgroundSize: 'cover',
      marginTop: '-107px',
      paddingTop: '107px',
      overflow: 'hidden'
    };
    let videoStyle = {
      position: 'absolute',
      height: (this.state.screenHeight + 107) || '780px',
      overflow: 'hidden',
      maxWidth: 'none',
      transform: 'translateZ(0)',
      backfaceVisibility: 'hidden',
      top: 0,
      left: `-${this.state.leftPosition}px`
    };
    let vidSrc = desktopVidSrc;

    if (window.innerWidth <= 640) {
      vidSrc = mobileVidSrc;
    }

    return (
      <div className="full-page-video" style={imageStyle}>
        <video
          loop
          playsInline
          muted
          autoPlay
          src={vidSrc}
          style={videoStyle}
          ref={(node) => this.videoEl = node}
          onLoadedData={this.setVideoStyleVals}
        >
        </video>
        {children}
      </div>
    );
  }
}

export default FullPageVideo;
