import React from 'react';
import Page from '../Page';
import PageSection from '../PageComponents/PageSection';
import HeroImage from '../Widgets/HeroImage';
import PageHeading from '../Widgets/PageHeading';
import SecondaryHeading from '../Widgets/SecondaryHeading';
import TextBlock from '../Widgets/TextBlock';
import BackgroundImageBlock from '../Widgets/BackgroundImageBlock';
import ImageHeader from '../Widgets/ImageHeader';
import isMobile from '../../utils/isMobile';

import './styles/clean-air.css';

import car_exhaust_desktop from '../../assets/images/clean-air/car_exhaust_desktop.jpg';
import car_exhaust_mobile from '../../assets/images/clean-air/car_exhaust_mobile.jpg';
import ev_icons from '../../assets/images/ev_icons.svg';
import highway_cliffs from '../../assets/images/clean-air/highway_cliffs.jpg';
import enjoy_clean_air from '../../assets/images/clean-air/enjoy_clean_air.jpg';
import utah_clean_cities from '../../assets/images/utah_clean_cities.png';
import breathe_utah from '../../assets/images/breathe_utah.png';

export default function() {
  document.title = 'Live Electric | Clean Air';

  return (
    <Page className="clean-air">
      <PageSection customClass="main-section">
        <HeroImage
          desktopSrc={car_exhaust_desktop}
          mobileSrc={car_exhaust_mobile}
          alt="Exhaust puffing out of a car's exhaust pipe"
        >
          <PageHeading text="CLEAN AIR" />
          <SecondaryHeading hideHR white>
            THIS AIR IS YOUR AIR
          </SecondaryHeading>
          <TextBlock whiteText>
            <p>
              Inversion. The Red Days. Emphysema. Lung issues. Our transportation is directly tied to the air we breathe. Owning an electric vehicle impacts this valley's lungs. You, your family, and your neighbors breathe better as a result of the decision to Live Electric.
            </p>
            <p>
              Here's how to connect with Western clean air organizations and be a clean-air citizen.
            </p>
          </TextBlock>
        </HeroImage>
      </PageSection>

      <PageSection customClass="intro">
        <SecondaryHeading hideHR>
          THIS AIR IS YOUR AIR
        </SecondaryHeading>
        <TextBlock>
          <p>
          Inversion. The Red Days. Emphysema. Lung issues. Our transportation is directly tied to the air we breathe. Owning an electric vehicle impacts this valley's lungs. You, your family, and your neighbors breathe better as a result of the decision to Live Electric.
          </p>
          <p>
          Here's how to connect with Western clean air organizations and be a clean-air citizen.
          </p>
        </TextBlock>
      </PageSection>

      <PageSection customClass="living-now">
        <ImageHeader
          bgSrc={ev_icons}
          imgClass="ev-icon ev-icon--cloud"
        >
          REDUCING GREENHOUSE GASES:<br />
          The Impact of Widespread EV Adoption
        </ImageHeader>
        <TextBlock>
          <p>
            Cars and light-duty trucks account for nearly 20% of carbon emissions in the transportation sector. Widespread vehicle electrification across the country could reduce greenhouse gas emissions by between 430 million metric tons and 550 million metric tons annually by 2050.
          </p>
        </TextBlock>
        <ImageHeader
          bgSrc={ev_icons}
          imgClass="ev-icon ev-icon--leaf-grey"
        >
          100 MILES OF EMISSIONS:<br />
          Nissan Leaf Vs. A Conventional Vehicle
        </ImageHeader>
        <TextBlock>
          <p>
            In Utah, a Nissan LEAF driver would avoid approximately 7.5 pounds of CO2 equivalent (lb-CO2e) per 100 miles driven compared to a conventional gasoline vehicle.
          </p>
          <p>
            <a href="https://www.ucsusa.org/clean-vehicles/electric-vehicles/ev-emissions-tool#.WmbDVZM-dE4" target="_blank" rel="noopener noreferrer" title="How clean is your electric vehicle?">How clean is <i>your</i> electric vehicle?</a>
          </p>
        </TextBlock>
        <hr className="faded-hr" />
      </PageSection>

      <PageSection customClass="breathe-utah">
        <BackgroundImageBlock src={highway_cliffs} blockHeight="423px">
          <SecondaryHeading white hideHR>
            BREATHE UTAH:<br />
            EDUCATING THIS GENERATION AND THE NEXT
          </SecondaryHeading>
          <img src={breathe_utah} alt="Breathe Utah" className="breathe-utah-logo" />
        </BackgroundImageBlock>
        <SecondaryHeading hideHR>
          AIR AWARE SCHOOL PROGRAMS
        </SecondaryHeading>
        <TextBlock>
          <p>
            Since its inception in 2010, Breathe Utah has brought the Air Aware program to over 10,000 students in grades pre-K through 12th, teaching students what air pollution is, where it comes from, how it affects the human body, and what we can do about it. Air Aware is an interactive presentation tailored to each grade level and can be delivered to individual classrooms or grade-wide assemblies.
          </p>
          <p>
            To learn more or to schedule a school visit, visit <a href="https://breatheutah.org" target="_blank" rel="noopener noreferrer" title="breathutah.org">breatheutah.org</a>
          </p>
        </TextBlock>
      </PageSection>

      <PageSection customClass="clean-cities">
        <BackgroundImageBlock
          src={enjoy_clean_air}
          blockHeight={(isMobile() ? '350px' : (window.innerWidth >= 1824 ? '500px' : '450px'))}
        >
          <SecondaryHeading white hideHR>
            LET THY NEIGHBOR BREATHE:<br />
            UTAH CLEAN CITIES
          </SecondaryHeading>
          <img src={utah_clean_cities} alt="Utah Clean Cities" className="utah-clean-cities" />
        </BackgroundImageBlock>
        <TextBlock>
          <p>
            <a href="https://utahcleancities.org/" target="_blank" rel="noopener noreferrer" title="Utah Clean Cities">Utah Clean Cities</a> is fostering clean-air citizenship by working with vehicle fleets, fuel providers, community leaders, and others to reduce petroleum use in transportation.
          </p>
          <p>
            Their idle-free campaign, Green Fleet initiatives, and consultation resources help guide individuals and businesses toward cleaner transportation solutions that are efficient and cost-effective.
          </p>
          <p>
            Utah Clean Cities can help you obtain grants and/or loans for the use of EVs which reduce your oil-dependent risk. They're doing their part to help Utahns reduce harmful emissions one car at a time,
            whether it be working or playing in our beautiful state.
          </p>
        </TextBlock>
      </PageSection>
    </Page>
  );
}
