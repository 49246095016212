import React from 'react';
import {Link} from 'react-router-dom';
import Page from '../Page';
import PageSection from '../PageComponents/PageSection';
import HeroImage from '../Widgets/HeroImage';
import PageHeading from '../Widgets/PageHeading';
import SecondaryHeading from '../Widgets/SecondaryHeading';
import TextBlock from '../Widgets/TextBlock';
import BackgroundImageBlock from '../Widgets/BackgroundImageBlock';
import ImageBlock from '../Widgets/ImageBlock';

import './styles/live-electric.css';

import logo_grey_white from '../../assets/images/logo_grey_white.svg';
import road_to_red_cliffs_desktop from '../../assets/images/live-electric/road_to_red_cliffs_desktop.jpg';
import road_to_red_cliffs_mobile from '../../assets/images/live-electric/road_to_red_cliffs_mobile.jpg';
import plugged_in_car from '../../assets/images/live-electric/plugged_in_car.jpg';
import hot_springs_carnival_zion from '../../assets/images/live-electric/hot_springs_carnival_zion.jpg';
import salt_lake_valley from '../../assets/images/live-electric/salt_lake_valley.jpg';

export default function () {
	document.title = 'Live Electric | Live Electric';

	return (
		<Page className="live-electric">
			<PageSection className="main-section">
				<HeroImage
					desktopSrc={road_to_red_cliffs_desktop}
					mobileSrc={road_to_red_cliffs_mobile}
					alt="Road in the desert leading up to red cliffs in the distance"
				>
					<PageHeading text="WEST SMART EV"/>
					<SecondaryHeading customClass="main-section-heading" hideHR white>
            <span className="heading-light">Empowering the Intermountain
<br/></span>
						{' '}West
						to Live Electric
					</SecondaryHeading>
				</HeroImage>
			</PageSection>

			<PageSection customClass="intro">
				<div className="video">
					<iframe src="https://player.vimeo.com/video/252271912" frameBorder="0"
									title="The Choice Has Never Been Easier" allowFullScreen></iframe>
				</div>
				<TextBlock>
					<p>
						The primary goal of WestSmartEV@Scale is to bring together multi-state regional activities with a common
						public interest in executing a strategic, coordinated, phased deployment of EV programs and charging
						infrastructure that will break down barriers to and accelerate EV adoption.
					</p>
					<p>
						The project provides for a unified, large-scale charging and vehicle data collection on all program
						activities, data analysis and processing, and reporting with public dissemination, which would not occur
						otherwise.
					</p>
					<p>
						Communities both large and small, urban, and rural, will benefit from this project’s generation of
						aggressive adoption activities and lessons learn.
					</p>
				</TextBlock>
			</PageSection>

			<PageSection customClass="who-is">
				<BackgroundImageBlock src={plugged_in_car}>
					<img src={logo_grey_white} className="in-page-logo--large" alt="live electric logo"/>
					<SecondaryHeading customClass="intro-section" white>
						WHO IS WEST SMART?
					</SecondaryHeading>
					<TextBlock whiteText>
						<p>
							The comprehensive and ambitious community partnership project includes more than 25 strategic partners
							spanning 7 states and addresses regional challenges in all five of the critical EV application focus areas
							including destination highways, underserved regions, urban mobility, freight and port electrification, and
							community and workplace charging.
						</p>
					</TextBlock>
				</BackgroundImageBlock>
			</PageSection>

			<PageSection customClass="charging">
				<SecondaryHeading>
					CHARGING WESTWARD
				</SecondaryHeading>
				<TextBlock>
					<p>
						The project provides coordinated efforts to electrify over 42,000 miles of regional interstate and state
						highway networks, and a commitment to continue funding regional expansion at scale based on best practices
						from the project through a 10-year program.
					</p>
				</TextBlock>
				<ImageBlock
					src={hot_springs_carnival_zion}
					alt="Hot springs, a woman at a carnival and a man at Zion National Park"
					imgWidth="100%"
				/>
			</PageSection>

			<PageSection customClass="why-matters">
				<SecondaryHeading>
					WHY IT MATTERS
				</SecondaryHeading>
				<TextBlock>
					<p>
						The WestSmartEV@Scale project creates an enduring regional ecosystem across the Intermountain West to
						sustain accelerated growth in freight, business and consumer use of electric vehicles. And the benefits will
						be felt for generations.
					</p>
				</TextBlock>
			</PageSection>

			<PageSection customClass="clean-air">
				<BackgroundImageBlock src={salt_lake_valley} blockHeight="600px">
					<SecondaryHeading white>
						IMAGINE CLEANER AIR
					</SecondaryHeading>
					<TextBlock>
						<p>
							This is our airshed. Small, daily transportation choices make a huge impact on the air we breathe
							together. Electric vehicle <Link to="/clean-air">purchasing</Link> and <Link to="/smart-mobility">smart
							mobility</Link> options are changing the daily impact on our collective lungs.
						</p>
					</TextBlock>
				</BackgroundImageBlock>
			</PageSection>
		</Page>
	);
}
