import React from 'react';
import Page from '../Page';
import PageSection from '../PageComponents/PageSection';
import HeroImage from '../Widgets/HeroImage';
import PageHeading from '../Widgets/PageHeading';
import SecondaryHeading from '../Widgets/SecondaryHeading';
import TextBlock from '../Widgets/TextBlock';
// import BackgroundImageBlock from '../Widgets/BackgroundImageBlock';

import './styles/incentives.css';

import incentives_hero_desktop from '../../assets/images/incentives/incentives_hero_desktop.jpg';
import incentives_hero_mobile from '../../assets/images/incentives/incentives_hero_mobile.jpg';
// import salt_lake_city_dusk from '../../assets/images/incentives/salt_lake_city_dusk.jpg';
// import leaf_pdf from '../../assets/documents/Nissan_LEAF_RMP_Discount_Flyer.pdf';
// import leaf_fleetail_pdf from '../../assets/documents/RMP_FY19_Q1_LEAF_Fleetail_Flyer.pdf';
import mobility_car_share from '../../assets/documents/Giv_Mobility_Carshare_Final_Report_2024.pdf';

/*
<TextBlock>
  <h3>
    <a href={leaf_pdf} target="_blank">
      Nissan Leaf EV Incentive
    </a>
  </h3>
  <p>
    $3,000 off a 2018 Nissan Leaf for Rocky Mountain Power customers. Deal ends January 2, 2019.
  </p>
</TextBlock>
*/

export default function () {
	document.title = 'Live Electric | Incentives';

	return (
		<Page className="incentives">
			<PageSection customClass="main-section">
				<HeroImage
					desktopSrc={incentives_hero_desktop}
					mobileSrc={incentives_hero_mobile}
					alt="Feet hanging out car window, seen in rearview mirror"
				>
					<PageHeading text="REPORTS"/>
					<TextBlock whiteText>
						<p>
							Outreach and education strategies to broadly disseminate information and technologies that build the
							regional electric transportation ecosystem
						</p>
					</TextBlock>
				</HeroImage>
			</PageSection>

			<PageSection customClass="first-section">
				<SecondaryHeading>
					<a href="https://rockymountainpower.net/savings-energy-choices/electric-vehicles.html" target="_blank"
						 rel="noopener noreferrer">ROCKY MOUNTAIN POWER INCENTIVES</a>
				</SecondaryHeading>
				<TextBlock>
					<h3 className="green-text" style={{lineHeight: '1.2'}}>
						Electric Residential Carshare Program:<br/>
						Final Report + Replication Toolkit<br/>
						By Giv Mobility
					</h3>
					<p>
						This report details an urban, transit-adjacent, and free electric carshare program exclusively for
						households in an affordable housing building in Salt Lake City, Utah. In it, key findings, a full review of
						the systems and hardware used, financial impacts, and a replication toolkit to aid interested entities in
						starting their own place-based, electric carshare program can be found.
					</p>
					<a href={mobility_car_share} target="_blank">Giv Mobility Carshare Final Report 2024 (PDF)</a>
				</TextBlock>
				{/*<TextBlock>*/}
				{/*	<h3>*/}
				{/*		<a href="https://rockymountainpower.net/savings-energy-choices/electric-vehicles.html" target="_blank"*/}
				{/*			 rel="noopener noreferrer">Rocky Mountain Power Charging Station Incentives</a>*/}
				{/*	</h3>*/}
				{/*	<p>New incentives for Electric Vehicle Supply Equipment (EVSE) are available to help improve air quality and*/}
				{/*		encourage development of electric vehicle charging infrastructure. Rocky Mountain Power incentives are*/}
				{/*		offered to Utah non-residential customers and multifamily dwellings to offset the cost of installing*/}
				{/*		charging stations.</p>*/}
				{/*</TextBlock>*/}
				{/*<TextBlock>*/}
				{/*	<h3>*/}
				{/*		<a href="https://rockymountainpower.net/savings-energy-choices/electric-vehicles.html" target="_blank"*/}
				{/*			 rel="noopener noreferrer">Rocky Mountain Power Residential Time of Use Incentive</a>*/}
				{/*	</h3>*/}
				{/*	<p>Offers EV owners discounted time-of-use rate plans for charging your car overnight and on the weekends*/}
				{/*		(during off-peak hours). Qualifying customers who participate for a year earn a $200 incentive.</p>*/}
				{/*	<p><span className="green-text">Option 1:</span> 22.2755 cents per kilo-watt hour during peak use times and*/}
				{/*		6.7881 cents per kWh off peak</p>*/}
				{/*	<p><span className="green-text">Option 2:</span> 34.3753 cents per kWh hour during peak use times and 3.4003*/}
				{/*		cents per kWh off peak</p>*/}
				{/*</TextBlock>*/}
				{/*<TextBlock>*/}
				{/*	<h3>*/}
				{/*		<a href="https://rockymountainpower.net/savings-energy-choices/electric-vehicles.html" target="_blank"*/}
				{/*			 rel="noopener noreferrer">EV Step Program</a>*/}
				{/*	</h3>*/}
				{/*	<p>For information about the EV Step Program, visit: <a*/}
				{/*		href="https://rockymountainpower.net/savings-energy-choices/electric-vehicles.html" target="_blank"*/}
				{/*		rel="noopener noreferrer">RockyMountainPower.net</a></p>*/}
				{/*</TextBlock>*/}
				{/*<TextBlock>*/}
				{/*	<h3>*/}
				{/*		<a href="https://rockymountainpower.net/savings-energy-choices/electric-vehicles.html" target="_blank"*/}
				{/*			 rel="noopener noreferrer">EV Fleet Grant</a>*/}
				{/*	</h3>*/}
				{/*	<p>The EV Fleet project is an exciting and financially appealing opportunity for Utah fleets to add electric*/}
				{/*		vehicles and charging infrastructure with great incentives- up to $5,000 off EV vehicles and 75% saving on*/}
				{/*		charging equipment*.*/}
				{/*		<br/>*/}
				{/*		<i style={{fontStyle: 'italic'}}>*Please read detail and talk with the Rocky Mountain Power team</i>.*/}
				{/*	</p>*/}
				{/*</TextBlock>*/}
			</PageSection>

			{/*<PageSection customClass="bg-image-section">*/}
			{/*	<BackgroundImageBlock src={salt_lake_city_dusk} blockHeight="770px">*/}
			{/*		<div className="bg-image-section__content">*/}
			{/*			<SecondaryHeading white>*/}
			{/*				CITY INCENTIVES*/}
			{/*			</SecondaryHeading>*/}
			{/*			<TextBlock whiteText>*/}
			{/*				<h3>*/}
			{/*					<a href="https://www.slc.gov/transportation/green-vehicle-parking-permits/" target="_blank"*/}
			{/*						 title="Free Metered Downtown SLC Parking" rel="noopener noreferrer">Free Metered Downtown SLC*/}
			{/*						Parking</a>*/}
			{/*				</h3>*/}
			{/*				<p>Vehicles that achieve a minimum designated city fuel economy or EPA Smog Rating are eligible for a*/}
			{/*					special parking permit that allows free parking for up to two hours at Salt Lake City (SLC) Parking*/}
			{/*					meters.</p>*/}
			{/*			</TextBlock>*/}
			{/*			<TextBlock whiteText>*/}
			{/*				<h3>*/}
			{/*					<a href="http://www.udot.utah.gov/main/f?p=100:pg::::1:T,V:2280" target="_blank"*/}
			{/*						 title="Alternative Fuel Vehicle Decal and High Occupancy Vehicle (HOV) Lane Exemption"*/}
			{/*						 rel="noopener noreferrer">Alternative Fuel Vehicle Decal and High Occupancy Vehicle (HOV) Lane*/}
			{/*						Exemption</a>*/}
			{/*				</h3>*/}
			{/*				<p>Drivers of vehicles that meet certain clean-fuel and emissions standards (listed below) may use Utah's*/}
			{/*					Express Lanes—otherwise known as high occupancy vehicle lanes—as a single occupant, provided they have a*/}
			{/*					Clean Fuel Decal and Permit. The Utah Department of Transportation issues the C Decal and Permit to*/}
			{/*					randomly chosen applicants. UDOT retains qualifying applications on a wait list, and retains unselected*/}
			{/*					applications for future drawings.</p>*/}
			{/*			</TextBlock>*/}
			{/*		</div>*/}
			{/*	</BackgroundImageBlock>*/}
			{/*</PageSection>*/}

			{/*<PageSection customClass="more-incentives">*/}
			{/*	<SecondaryHeading>*/}
			{/*		STATE INCENTIVES*/}
			{/*	</SecondaryHeading>*/}
			{/*	<TextBlock>*/}
			{/*		<h3>*/}
			{/*			<a href="https://deq.utah.gov/air-quality/incentive-programs-aq/air-quality-incentive-programs"*/}
			{/*				 target="_blank" rel="noopener noreferrer">Clean Fuel Grant Program</a>*/}
			{/*		</h3>*/}
			{/*		<p>The Utah Clean Fuels and Vehicle Technology Grant Program provides grants to assist businesses and*/}
			{/*			government entities in covering:</p>*/}
			{/*		<ul>*/}
			{/*			<li>Up to 50% of the cost of converting vehicles to a cleaner burning fuel (minus the amount of any tax*/}
			{/*				credit claimed),*/}
			{/*			</li>*/}
			{/*			<li>Up to 50% of the incremental cost of a clean fuel OEM vehicle as defined in 19-1-402 (minus the amount*/}
			{/*				of any tax credit claimed), or*/}
			{/*			</li>*/}
			{/*			<li>The cost of clean fuel refueling equipment for a private sector business vehicle or a government vehicle*/}
			{/*				as defined in 19-1-402.*/}
			{/*			</li>*/}
			{/*		</ul>*/}
			{/*	</TextBlock>*/}
			{/*</PageSection>*/}

			{/*<PageSection customClass="clean-cities">*/}
			{/*	<SecondaryHeading>*/}
			{/*		LIVE ELECTRIC CLEAN CITIES*/}
			{/*	</SecondaryHeading>*/}
			{/*	<TextBlock>*/}
			{/*		<p><a href="https://www.dmv.org/ut-utah/green-driver-state-incentives.php" target="_blank"*/}
			{/*					rel="noopener noreferrer">Comprehensive overview of Utah Green Driver Incentives</a></p>*/}
			{/*	</TextBlock>*/}

			{/*	<hr className="faded-hr"/>*/}
			{/*</PageSection>*/}
		</Page>
	);
}
