import React from 'react';
import Page from '../Page';
import PageSection from '../PageComponents/PageSection';
import HeroImage from '../Widgets/HeroImage';
import PageHeading from '../Widgets/PageHeading';
import SecondaryHeading from '../Widgets/SecondaryHeading';
import TextBlock from '../Widgets/TextBlock';
import BackgroundImageBlock from '../Widgets/BackgroundImageBlock';
import ImageBlock from '../Widgets/ImageBlock';
import PlayVideoButton from '../Widgets/PlayVideoButton';
import isMobile from '../../utils/isMobile';

import './styles/smart-mobility.css';

import smart_mobility_desktop from '../../assets/images/smart-mobility/smart_mobility_desktop.jpg';
import smart_mobility_mobile from '../../assets/images/smart-mobility/smart_mobility_mobile.jpg';
import autonomous from '../../assets/images/smart-mobility/autonomous.jpg';
import vehicle_icons from '../../assets/images/smart-mobility/vehicle_icons.svg';
import electric_bikes from '../../assets/images/smart-mobility/electric_bikes.jpg';
import proterra_bus from '../../assets/images/smart-mobility/proterra_bus.jpg';
import shared from '../../assets/images/smart-mobility/shared.jpg';

export default function() {
  document.title = 'Live Electric | Smart Mobility';

  return (
    <Page className="smart-mobility">
      <PageSection customClass="main-section">
        <HeroImage
          desktopSrc={smart_mobility_desktop}
          mobileSrc={smart_mobility_mobile}
          alt="Man standing holding an iPad with headphones in"
        >
          <PageHeading text="SMART MOBILITY" />
          <div className="video-heading">
            <PlayVideoButton />
          </div>
          <SecondaryHeading hideHR white>
            Gearing Up the<br />
            Smart Movement<br />
            Transportation Machine
          </SecondaryHeading>
        </HeroImage>
      </PageSection>

      <PageSection customClass="intro">
        <TextBlock customClass="intro-text">
          <p>
            Live Electric is rewiring transportation by applying and promoting forward-thinking mobility
            technology and strategies. We’re deploying electric, autonomous, and shared approaches
            to change the way we think, live, and move around the West.
          </p>
          <p>
            We’re excited about what’s already happening in our community and what’s coming before you know it.
            It’s not city of the future stuff. Here’s how we’re Living Electric now:
          </p>
        </TextBlock>
        <hr className="faded-hr" />
        <ImageBlock src={vehicle_icons} alt="Electric vehicle icons" />
      </PageSection>

      <PageSection customClass="electric-bikes">
        <SecondaryHeading hideHR>
          ELECTRIC BIKES
        </SecondaryHeading>
        <TextBlock>
          <p>
            After conducting a comprehensive pilot study, Park City, Utah, set out a plan to encourage all three classes of electric assisted bikes (e-bikes) on strategic pathways in and around Park City. The project serves as a model for demonstrating more ways individuals and communities can get moving. It’s a fun, zero-emissions way to get around.
          </p>
        </TextBlock>
        <ImageBlock src={electric_bikes} alt="Electric bikes" />
      </PageSection>

      <PageSection customClass="electric-buses">
        <SecondaryHeading hideHR>
          ELECTRIC BUSES
        </SecondaryHeading>
        <TextBlock>
          <p>
            Summit County has made a serious commitment to making its net-zero carbon footprint goal by 2022. Electric buses are a major part of that effort. Park City Transit will roll out Proterra buses throughout the mountain community. Dubbed "The Electric Xpress," the new fleet will become the first battery-electric transit service in a U.S. mountain resort community.
          </p>
        </TextBlock>
        <ImageBlock src={proterra_bus} alt="Battery statuses" />
      </PageSection>

      <PageSection customClass="autonomous">
        <SecondaryHeading hideHR>
          AUTONOMOUS
        </SecondaryHeading>
        <TextBlock>
          <p>
            More than just "self-driving vehicles" autonomous makes driving quick, efficient and safe. Computers do the driving and calculate road conditions, reroute traffic, and let other vehicles know of potential problems before they get there. The Utah State Legislature started establishing best practices for regulating autonomous vehicles on Utah highways in 2014.
          </p>
        </TextBlock>
        <ImageBlock src={autonomous} alt="Dashboard" />
      </PageSection>

      <PageSection customClass="shared">
        <SecondaryHeading hideHR>
          SHARED
        </SecondaryHeading>
        <TextBlock>
          <p>
            Shared is more than carpooling in the new era of mobility. It means using a networked grid of vehicles, websites and apps to link passengers with drivers and eliminate complex traffic inefficiencies. To that end, Uber Electric is launching in Salt Lake and Park City. That means, by 2018 there will be a totally networked driving community and the charging infrastructure to support it.
          </p>
        </TextBlock>
        <BackgroundImageBlock
          src={shared}
          blockHeight={isMobile() ? '456px' : '856px'}
        >
          <SecondaryHeading white hideHR>
            "YOU MEAN CARPOOLING, RIGHT?"<br />
            "NOT QUITE..."
          </SecondaryHeading>
        </BackgroundImageBlock>
      </PageSection>
    </Page>
  );
}
