import React from 'react';
import 'react-app-polyfill/ie11';
import {render} from 'react-snapshot';

import './assets/fonts/FashionFetishBold.ttf';
import './assets/fonts/FashionFetishLight.ttf';
import './index.css';

import App from './App';
import {unregister} from './registerServiceWorker';

render(<App />, document.getElementById('root'));
unregister();
