import React from 'react';

import play_video from '../../assets/images/play_video.svg';
import './styles/play-video-button.css'

function PlayVideoButton(props) {
  return (
    <button className="play-video">
      <img src={play_video} alt="Play video" />
    </button>
  );
}

export default PlayVideoButton;
