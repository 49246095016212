import React from 'react';
import PropTypes from 'prop-types';

class BackgroundImageBlock extends React.Component {
  static propTypes = {
    // This component needs to wrap the others that go with the image
    // in order to get the desired effect
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    src: PropTypes.string.isRequired,
    blockHeight: PropTypes.string
  };

  static defaultProps = {
    blockHeight: '50%'
  };

  render() {
    const blockStyle = {
      background: `url(${this.props.src}) no-repeat center center`,
      backgroundSize: 'cover',
      height: this.props.blockHeight
    };

    return (
      <div className="bg-image-block" style={blockStyle}>
        {this.props.children}
      </div>
    );
  }
}

export default BackgroundImageBlock;
