import React from 'react';
import Page from '../Page';
import PageSection from '../PageComponents/PageSection';
import PageHeading from '../Widgets/PageHeading';
import SecondaryHeading from '../Widgets/SecondaryHeading';
import TextBlock from '../Widgets/TextBlock';

import './styles/about.css';

import breathe_utah from '../../assets/images/breathe_utah.png';
import rocky_mountain_power from '../../assets/images/about/rocky_mountain_power.jpg';
import idaho_labs from '../../assets/images/about/idaho_labs.png';
import u_smart_energy from '../../assets/images/about/u_smart_energy.png';
import forth_mobility from '../../assets/images/forth_mobility.jpg';
import utah_clean_cities from '../../assets/images/about/utah_clean_cities.jpg';
import utah_state_u from '../../assets/images/about/utah_state_u.jpg';
import utah_clean_energy from '../../assets/images/about/utah_clean_energy.png';
import park_city from '../../assets/images/about/park_city.jpeg';
import slc_green from '../../assets/images/about/slc_green.png';
import utah_deq from '../../assets/images/about/utah_deq.png';
import uvu from '../../assets/images/about/uvu.png';
import weber_state_u from '../../assets/images/about/weber_state_u.png';
import doe_clean_cities from '../../assets/images/about/doe_clean_cities.jpg';
import leaders_for_clean_air from '../../assets/images/leaders_for_clean_air.png';

export default function() {
  document.title = 'Live Electric | About Us';

  return (
    <Page className="about">
      <PageSection customClass="main-section">
        <PageHeading text="ABOUT US" dark />
        <SecondaryHeading hideHR>
          Who Is Live Electric?
        </SecondaryHeading>
        <TextBlock>
          <p>
            We are a community partnership dedicated to accelerating electric vehicle adoption, zero-emission strategies and smart mobility transportation choices to promote clean air, innovation and economic vitality in the Intermountain West.
          </p>
          <p>
            Our community-driven collaborative was brought together by Rocky Mountain Power, the Department of Energy, Utah Clean Cities, and a number of state and community partners for the purpose of making the individual, business and community benefits of EV adoption a reality. Today.
          </p>
          <p>
            Further, Live Electric is advancing our electric transportation infrastructure. The greater Salt Lake City area will become a seamlessly connected grid of smart mobility options. And Westerners will utilize accessible charging stations at convenient intervals, drive and share more electric vehicles, and travel smart up and down major highways, from Yellowstone to Zion and from Arches to Disneyland.
          </p>
          <p>
            Because our transportation lifestyles have a direct impact on our community health, Live Electric is taking action for a less oil-dependent transportation economy, fundamentally changing the way we move around the West.
          </p>
        </TextBlock>
        <div className="contact">
          <h3>Contact</h3>
          <a href="mailto:info@liveelectric.org">info@liveelectric.org</a>
        </div>
      </PageSection>
      <PageSection customClass="community-partners">
        <SecondaryHeading>
          Community Partners
        </SecondaryHeading>
        <div className="partner-logos partner-logos--first-row-mobile">
          <a href="https://www.rockymountainpower.net/env/ev.html" target="_blank" rel="noopener noreferrer" title="Rocky Mountain Power" className="large-logo">
            <img src={rocky_mountain_power} alt="Rocky Mountain Power Logo" />
          </a>
        </div>
        <div className="partner-logos partner-logos--first-row">
          <a href="https://www.slcgreen.com/" target="_blank" rel="noopener noreferrer" title="City of Salt Lake">
            <img src={slc_green} alt="City of Salt Lake" />
          </a>
          <a href="https://deq.utah.gov/" target="_blank" rel="noopener noreferrer" title="State of Utah (DEQ)">
            <img src={utah_deq} alt="State of Utah (DEQ) Logo" />
          </a>
          <a href="https://www.weber.edu/sustainability/default.html" target="_blank" rel="noopener noreferrer" title="Weber State University">
            <img src={weber_state_u} alt="Weber State University Logo" />
          </a>
          <a href="https://usmart.ece.utah.edu/" target="_blank" rel="noopener noreferrer" title="University of Utah">
            <img src={u_smart_energy} alt="University of Utah Smart Energy Logo" />
          </a>
        </div>

        <div className="partner-logos partner-logos--second-row">
          <a href="https://www.inl.gov/" target="_blank" rel="noopener noreferrer" title="Idaho National Laboratory" className="side-logo">
            <img src={idaho_labs} alt="Idaho National Laboratory Logo" />
          </a>
          <a href="https://www.rockymountainpower.net/env/ev.html" target="_blank" rel="noopener noreferrer" title="Rocky Mountain Power" className="large-logo">
            <img src={rocky_mountain_power} alt="Rocky Mountain Power Logo" />
          </a>
          <a href="https://www.uvu.edu/sustainability/" target="_blank" rel="noopener noreferrer" title="Utah Valley University" className="side-logo">
            <img src={uvu} alt="Utah Valley University Logo" />
          </a>
        </div>

        <div className="partner-logos partner-logos--second-row-mobile">
          <a href="https://www.inl.gov/" target="_blank" rel="noopener noreferrer" title="Idaho National Laboratory">
            <img src={idaho_labs} alt="Idaho National Laboratory Logo" />
          </a>
          <a href="https://www.uvu.edu/sustainability/" target="_blank" rel="noopener noreferrer" title="Utah Valley University">
            <img src={uvu} alt="Utah Valley University Logo" />
          </a>
        </div>

        <div className="partner-logos partner-logos--third-row">
          <a href="https://parkcity.org/departments/sustainability/environmental-sustainability" target="_blank" rel="noopener noreferrer" title="City of Park City" className="side-logo">
            <img src={park_city} alt="City of Park City Logo" />
          </a>
          <div className="large-logo" >&nbsp;</div>
          <a href="https://forthmobility.org/" target="_blank" rel="noopener noreferrer" title="Forth Mobility" className="side-logo">
            <img src={forth_mobility} alt="Forth Mobility Logo" />
          </a>
        </div>

        <div className="partner-logos partner-logos--fourth-row">
          <a href="https://cleancities.energy.gov/" target="_blank" rel="noopener noreferrer" title="U.S. Department of Energy Clean Cities" className="side-logo">
            <img src={doe_clean_cities} alt="U.S. Department of Energy Clean Cities Logo" />
          </a>
          <div className="large-logo" >&nbsp;</div>
          <a href="http://leadersforcleanair.org/" target="_blank" rel="noopener noreferrer" title="Leaders for Clean Air" className="side-logo leaders-clean-air">
            <img src={leaders_for_clean_air} alt="Leaders for clean air" />
          </a>
        </div>

        <div className="partner-logos partner-logos--fifth-row">
          <a href="http://evr.usu.edu/index" target="_blank" rel="noopener noreferrer" title="Utah State University Logo">
            <img src={utah_state_u} alt="Utah State University Logo" />
          </a>
          <a href="https://www.breatheutah.org/" target="_blank" rel="noopener noreferrer" title="Breathe Utah">
            <img src={breathe_utah} alt="Breath Utah Logo" />
          </a>
          <a href="https://utahcleancities.org/" target="_blank" rel="noopener noreferrer" title="Utah Clean Cities">
            <img src={utah_clean_cities} alt="Utah Clean Cities Logo" />
          </a>
          <a href="https://utahcleanenergy.org/" target="_blank" rel="noopener noreferrer" title="Utah Clean Energy">
            <img src={utah_clean_energy} alt="Utah Clean Energy Logo" />
          </a>
        </div>

        <hr className="faded-hr" />
      </PageSection>
    </Page>
  );
}
