import React from 'react';
import Page from '../Page';
import PageSection from '../PageComponents/PageSection';
import HeroImage from '../Widgets/HeroImage';
import PageHeading from '../Widgets/PageHeading';
import SecondaryHeading from '../Widgets/SecondaryHeading';
import TextBlock from '../Widgets/TextBlock';
import ImageHeader from '../Widgets/ImageHeader';
import Map from '../Widgets/Map';
import isMobile from '../../utils/isMobile';

import './styles/electric-hwys.css';

import white_car_highway_desktop from '../../assets/images/electric-hwys/white_car_highway_desktop.jpg';
import white_car_highway_mobile from '../../assets/images/electric-hwys/white_car_highway_mobile.jpg';
import map_pin from '../../assets/images/electric-hwys/map_pin.svg';

export default function() {
  document.title = 'Live Electric | Electric Highways';

  return (
    <Page className="electric-hwys">
      <PageSection customClass="main-section">
        <HeroImage
          desktopSrc={white_car_highway_desktop}
          mobileSrc={white_car_highway_mobile}
          alt="A white car driving down the highway in the mountains"
        >
          <PageHeading text="ELECTRIC HIGHWAYS" />
          <SecondaryHeading hideHR white>
            THE INTERMOUNTAIN WEST REGION IS<br />
            WIRED FOR THE NEXT GENERATION<br />
            OF ELECTRIC VEHICLES
        </SecondaryHeading>
        </HeroImage>
      </PageSection>

      <PageSection customClass="intro">
        <SecondaryHeading hideHR>
          THE INTERMOUNTAIN WEST REGION IS<br />
          WIRED FOR THE NEXT GENERATION<br />
          OF ELECTRIC VEHICLES
        </SecondaryHeading>
        <TextBlock>
          <p>
            Live Electric is electrifying the I-15, I-80 and I-70 highway corridors
            with conveniently placed fast-charging stations, building the EV infrastructure
            for current EV owners and the anticipated wave of next generation EVs in the next few years.
            We're giving the West a jump. Here's how to find the convenient charging stations near you
            that suit your EVs specific charging needs.
          </p>
        </TextBlock>
      </PageSection>

      <PageSection customClass="map-container">
        <ImageHeader
          src={map_pin}
          imgWidth={isMobile() ? '90px' : '128px'}
          alt="Map pin"
        >
          FIND YOUR RANGE:<br />
          Charger Locations Near YOU
        </ImageHeader>

        <hr className="faded-hr" />

        <Map />
      </PageSection>
    </Page>
  );
}
