import React from 'react';

export default function(props) {
  let sectionClass = 'page-section';

  if (props.customClass) {
    sectionClass += ` ${props.customClass}`
  }

  return (
    <section className={sectionClass}>
      {props.children}
    </section>
  );
}
