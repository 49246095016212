import React from 'react';

import facebook from '../../assets/images/social/facebook.svg';
import twitter from '../../assets/images/social/twitter.svg';
import pinterest from '../../assets/images/social/pinterest.svg';
import instagram from '../../assets/images/social/instagram.svg';

import './styles/social-links.css';

export default function() {
  return (
    <div className="social-links">
      <a key="facebook" href="https://www.facebook.com/LiveElectricNow/" target="_blank" rel="noopener noreferrer">
        <img src={facebook} alt="Live Electric Facebook Page" />
      </a>
      <a key="twitter" href="https://twitter.com/liveelectricnow" target="_blank" rel="noopener noreferrer">
        <img src={twitter} alt="Live Electric Twitter Profile" />
      </a>
      <a key="instagram" href="https://www.instagram.com/liveelectricnow/" target="_blank" rel="noopener noreferrer">
        <img src={instagram} alt="Live Electric Instagram Profile" />
      </a>
      <a key="pinterest" href="https://www.pinterest.com/LiveElectricNow/liveelectric/" target="_blank" rel="noopener noreferrer">
        <img src={pinterest} alt="Live Electric Pinterest Page" />
      </a>
    </div>
  );
}
