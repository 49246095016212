import React from 'react';
import PropTypes from 'prop-types';

class ImageBlock extends React.Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    imgHeight: PropTypes.string,
    imgWidth: PropTypes.string,
    imgMaxWidth: PropTypes.string
  };

  static defaultProps = {
    alt: '',
    imgHeight: null,
    imgWidth: '100%',
    imgMaxWidth: null
  };

  render() {
    const imgStyle = {};

    if (this.props.imgHeight) {
      imgStyle.height = this.props.imgHeight;
    }

    if (this.props.imgWidth) {
      imgStyle.width = this.props.imgWidth;
    }

    if (this.props.imgMaxWidth) {
      imgStyle.maxWidth = this.props.imgMaxWidth;
    }

    return (
      <div className="image-block">
        <img src={this.props.src} alt={this.props.alt} style={imgStyle} />
      </div>
    );
  }
}

export default ImageBlock;
