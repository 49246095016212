export default [
  {
    "name": "Rocky Mountain Power",
    "url": "rockymountainpower.net",
    "geo": {
      "lat": 40.770866,
      "lng": -111.931778
    },
    "type": "Partner",
    "showInfo": false
  },
  {
    "name": "Utah Clean Cities",
    "url": "http://utahcleancities.org/",
    "geo": {
      "lat": 40.75956,
      "lng": -111.886787
    },
    "type": "Partner",
    "showInfo": false
  },
  {
    "name": "City of Salt Lake",
    "url": "http://www.slcgov.com/",
    "geo": {
      "lat": 40.76002,
      "lng": -111.88769
    },
    "type": "Partner",
    "showInfo": false
  },
  {
    "name": "Utah Clean Energy",
    "url": "https://utahcleanenergy.org/",
    "geo": {
      "lat": 40.771507,
      "lng": -111.8591
    },
    "type": "Partner",
    "showInfo": false
  },
  {
    "name": "City of Park City",
    "url": "http://parkcity.org/",
    "geo": {
      "lat": 40.644169,
      "lng": -111.49472
    },
    "type": "Partner",
    "showInfo": false
  },
  {
    "name": "Leaders for Clean Air",
    "url": "http://leadersforcleanair.org/",
    "geo": {
      "lat": 40.742794,
      "lng": -111.981461
    },
    "type": "Partner",
    "showInfo": false
  },
  {
    "name": "Breathe Utah",
    "url": "https://www.breatheutah.org/",
    "geo": {
      "lat": 40.7463,
      "lng": -111.83503
    },
    "type": "Partner",
    "showInfo": false
  },
  {
    "name": "State of Utah",
    "url": "https://www.utah.gov/",
    "geo": {
      "lat": 40.778041,
      "lng": -111.888133
    },
    "type": "Partner",
    "showInfo": false
  },
  {
    "name": "Idaho National Laboratory",
    "url": "https://www.inl.gov/",
    "geo": {
      "lat": 43.517223,
      "lng": -112.049347
    },
    "type": "Partner",
    "showInfo": false
  },
  {
    "name": "Utah State University",
    "url": "http://www.usu.edu/",
    "geo": {
      "lat": 41.74432,
      "lng": -111.811
    },
    "type": "Partner",
    "showInfo": false
  },
  {
    "name": "University of Utah",
    "url": "http://www.utah.edu/",
    "geo": {
      "lat": 40.76432,
      "lng": -111.8475
    },
    "type": "Partner",
    "showInfo": false
  },
  {
    "name": "Utah Valley University",
    "url": "http://www.uvu.edu/",
    "geo": {
      "lat": 40.27486,
      "lng": -111.7124
    },
    "type": "Partner",
    "showInfo": false
  },
  {
    "name": "Weber State",
    "url": "http://www.weber.edu/",
    "geo": {
      "lat": 41.19604,
      "lng": -111.9488
    },
    "type": "Partner",
    "showInfo": false
  },
  {
    "name": "Maverick",
    "url": "https://www.maverik.com/",
    "geo": {
      "lat": 40.76528,
      "lng": -111.88791
    },
    "type": "Partner",
    "showInfo": false
  },
  {
    "name": "Packsize",
    "url": "http://www.packsize.com/",
    "geo": {
      "lat": 40.742794,
      "lng": -111.981461
    },
    "type": "Partner",
    "showInfo": false
  },
  {
    "name": "Summit County",
    "url": "http://www.co.summit.ut.us/",
    "geo": {
      "lat": 40.91812,
      "lng": -111.39894
    },
    "type": "Partner",
    "showInfo": false
  },
  {
    "name": "Envision Utah",
    "url": "http://www.envisionutah.org/",
    "geo": {
      "lat": 40.763569,
      "lng": -111.874443
    },
    "type": "Partner",
    "showInfo": false
  },
  {
    "name": "Forth Mobility",
    "url": "https://forthmobility.org/",
    "geo": {
        "lat": 45.533173,
        "lng": -122.689293
      },
      "type": "Partner",
      "showInfo": false
  },
  {
    "name": "Wasatch Front Regional Council",
    "url": "http://www.wfrc.org/",
    "geo": {
      "lat": 40.77459,
      "lng": -112.020592
    },
    "type": "Partner",
    "showInfo": false
  },
  {
    "name": "University of Utah - Northwest Parking Garage",
    "address": "10 S 1510 E",
    "geo": {
      "lat": 40.736776,
      "lng": -111.9191097
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "University of Utah - Law Library",
    "address": "383 University St E",
    "geo": {
      "lat": 40.7613031,
      "lng": -111.8519272
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "Summit County Library",
    "address": "1885 W Ute Blvd",
    "geo": {
      "lat": 40.7237307,
      "lng": -111.5460383
    },
    "type": "DC Fast Charging Station",
    "showInfo": false
  },
  {
    "name": "Maverick",
    "address": "965 Hoodoo way",
    "geo": {
      "lat": 37.1446147,
      "lng": -113.4877809
    },
    "type": "DC Fast Charging Station",
    "showInfo": false
  },
  {
    "name": "Maverick",
    "address": "965 UT-99",
    "geo": {
      "lat": 38.948219,
      "lng": -112.345855
    },
    "type": "DC Fast Charging Station",
    "showInfo": false
  },
  {
    "name": "Maverick",
    "address": "725 E Main Street",
    "geo": {
      "lat": 39.9758,
      "lng": -111.769867
    },
    "type": "DC Fast Charging Station",
    "showInfo": false
  },
  {
    "name": "Rocky Mountain Power NTO",
    "address": "1407 W North Temple",
    "geo": {
      "lat": 40.7709729,
      "lng": -111.9316
    },
    "type": "DC Fast Charging Station",
    "showInfo": false
  },
  {
    "name": "Salt Lake City - Street Parking",
    "address": "255 E 500 S",
    "geo": {
      "lat": 40.758451,
      "lng": -111.8841762
    },
    "type": "DC Fast Charging Station",
    "showInfo": false
  },
  {
    "name": "Packsize International (2 stations)",
    "address": "3760 Commons Ln",
    "geo": {
      "lat": 40.7432098,
      "lng": -111.9811899
    },
    "type": "DC Fast Charging Station",
    "showInfo": false
  },
  {
    "name": "Maverick",
    "address": "444 Old US Highwat 91",
    "geo": {
      "lat": 37.7309789,
      "lng": -113.054388
    },
    "type": "DC Fast Charging Station",
    "showInfo": false
  },
  {
    "name": "Ken Garff Nissan of Salt Lake City",
    "address": "777 S West Temple",
    "geo": {
      "lat": 40.7524646,
      "lng": -111.8929097
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "Salt Lake County Government Center",
    "address": "2001 S State St",
    "geo": {
      "lat": 40.7273935,
      "lng": -111.8873507
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "Tim Dahle Nissan",
    "address": "4528 S State St",
    "geo": {
      "lat": 40.67296820000001,
      "lng": -111.8891854
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "REI",
    "address": "3285 E 3300 S",
    "geo": {
      "lat": 40.7008332,
      "lng": -111.8010118
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "Tim Dahle Nissan",
    "address": "955 N 400 E",
    "geo": {
      "lat": 40.8589867,
      "lng": -111.9029351
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "Tim Dahle Nissan Southtowne",
    "address": "11155 S Jordan Gateway",
    "geo": {
      "lat": 40.5490229,
      "lng": -111.897288
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "Daybreak - SoDa Row",
    "address": "4628 Daybreak Rim Way",
    "geo": {
      "lat": 40.5464202,
      "lng": -112.0013128
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "Ken Garff Nissan of Riverdale",
    "address": "615 W Riverdale Rd",
    "geo": {
      "lat": 41.179894,
      "lng": -111.9937022
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "UVU",
    "address": "936 S 400 W",
    "geo": {
      "lat": 40.2803711,
      "lng": -111.7051756
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "UVU",
    "address": "College Dr",
    "geo": {
      "lat": 40.2800542,
      "lng": -111.714975
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "Ken Garff Nissan of Orem",
    "address": "285 W University Pkwy",
    "geo": {
      "lat": 40.2728467,
      "lng": -111.7016859
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "City Creek Center East",
    "address": "50 S Main St",
    "geo": {
      "lat": 40.768056,
      "lng": -111.889722
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "Dennis Dillon Nissan",
    "address": "8727 Fairview Ave",
    "geo": {
      "lat": 43.61878,
      "lng": -116.290724
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "Harmons City Creek",
    "address": "135 E 100 S",
    "geo": {
      "lat": 40.767818,
      "lng": -111.887314
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "Newpark Resort",
    "address": "1456 Newpark Blvd",
    "geo": {
      "lat": 40.722379,
      "lng": -111.538952
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "Pocatello Nissan Kia",
    "address": "1900 Flandro Dr",
    "geo": {
      "lat": 42.909934,
      "lng": -112.450649
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "Ron Sayer Nissan",
    "address": "1175 N Woodruff Ave",
    "geo": {
      "lat": 43.5070904,
      "lng": -111.9997573
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "State of Utah MASOB building",
    "address": "195 N 1950 W",
    "geo": {
      "lat": 40.773506,
      "lng": -111.94735
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "Thanksgiving Point - Megaplex",
    "address": "3003 N Thanksgiving Way",
    "geo": {
      "lat": 40.423958,
      "lng": -111.884018
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "The Gateway - Summer Parking 4C",
    "address": "18 N Rio Grande St",
    "geo": {
      "lat": 40.766945,
      "lng": -111.903053
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "Utah State University EVR",
    "address": "670 E 1650 N",
    "geo": {
      "lat": 41.762158,
      "lng": -111.818947
    },
    "type": "Charging Station",
    "showInfo": false
  },
  {
    "name": "Young Chevrolet",
    "address": "645 N Main St",
    "geo": {
      "lat": 41.068842,
      "lng": -111.97619
    },
    "type": "Charging Station",
    "showInfo": false
  }
]
