import React from 'react';
import PropTypes from 'prop-types';

// All other pages have this component within them
// The children of this component are typically PageSection components
class Page extends React.Component {
  static propTypes = {
    className: PropTypes.string
  };

  static defaultProps = {
    className: 'page'
  };

  render() {
    return (
      <div className={this.props.className}>
        {this.props.children}
      </div>
    );
  }
}

export default Page;
