import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

// Common page components
import NavMenu from './Components/Layout/NavMenu';
import NavMenuItem from './Components/Layout/NavMenuItem';
import Footer from './Components/Layout/Footer';
import ScrollToTop from './Components/ScrollToTop';

// Pages
import Home from './Components/Pages/Home';
import NewHome from './Components/Pages/NewHome';
import About from './Components/Pages/About';
import LiveElectric from './Components/Pages/LiveElectric';
import WhyEV from './Components/Pages/WhyEV';
import CleanAir from './Components/Pages/CleanAir';
import ElectricHighways from './Components/Pages/ElectricHighways';
import SmartMobility from './Components/Pages/SmartMobility';
import EVWorkplace from './Components/Pages/EVWorkplace';
import Incentives from './Components/Pages/Incentives';
import FourOhFour from './Components/Pages/FourOhFour';

class App extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop>
          <div className="App">
            <NavMenu topMenu>
              <NavMenuItem route="/live-electric">
                WEST SMART EV
              </NavMenuItem>
              <NavMenuItem route="/why-ev">
                PORTS
              </NavMenuItem>
              <NavMenuItem route="/clean-air">
                UNDERSERVED REGIONS
              </NavMenuItem>
              <NavMenuItem route="/electric-highways">
                DESTINATION HIGHWAYS
              </NavMenuItem>
              <NavMenuItem route="/smart-mobility">
                URBAN MOBILITY
              </NavMenuItem>
              <NavMenuItem route="/ev-workplace">
                COMMUNITY & WORKPLACE
              </NavMenuItem>
              <NavMenuItem route="/reports">
                REPORTS
              </NavMenuItem>
              <NavMenuItem route="/about-us">
                About
              </NavMenuItem>
            </NavMenu>

            <Switch>
              <Route exact path="/" component={NewHome} />
              <Route path="/about-us" component={About} />
              <Route path="/live-electric" component={LiveElectric} />
              <Route path="/why-ev" component={WhyEV} />
              <Route path="/clean-air" component={CleanAir} />
              <Route path="/electric-highways" component={ElectricHighways} />
              <Route path="/smart-mobility" component={SmartMobility} />
              <Route path="/ev-workplace" component={EVWorkplace} />
              <Route path="/reports" component={Incentives} />
              <Route path="*" component={FourOhFour} />
            </Switch>

            <Footer />
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
