import React from 'react';
import Page from '../Page';
import PageSection from '../PageComponents/PageSection';
import SecondaryHeading from '../Widgets/SecondaryHeading';
import TextBlock from '../Widgets/TextBlock';

import './styles/four-oh-four.css';

export default function FourOhFour() {
  return (
    <Page className="page-not-found">
      <PageSection>
        <SecondaryHeading green hideHR>
          404: Page Not Found
        </SecondaryHeading>
        <TextBlock>
          <p>
            The page you are looking for could not be found.
          </p>
        </TextBlock>
      </PageSection>
    </Page>
  );
}
