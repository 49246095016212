import React from 'react';
import {Link} from 'react-router-dom';
import Page from '../Page';
import PageSection from '../PageComponents/PageSection';
import HeroImage from '../Widgets/HeroImage';
import SecondaryHeading from '../Widgets/SecondaryHeading';
import TextBlock from '../Widgets/TextBlock';
import ImageBlock from '../Widgets/ImageBlock';
import isMobile from '../../utils/isMobile';

import './styles/why-ev.css';
import './styles/new-home.css';

import sunset_road_desktop from '../../assets/images/new-home/sunset_road_desktop.jpg';
import sunset_road_mobile from '../../assets/images/new-home/sunset_road_mobile.jpg';
import west_smart_ev_infographic_desktop from '../../assets/images/new-home/west_smart_ev_infographic_desktop.png';
import west_smart_ev_infographic_mobile from '../../assets/images/new-home/west_smart_ev_infographic_mobile.png';
import planned_charger_map from '../../assets/images/new-home/planned_charger_map.png';

let imageHeights = {
	driveFinder: {
		desktop: '890px', mobile: '500px'
	}, leaf: {
		desktop: '774px', mobile: '474px'
	}
};

export default function () {
	document.title = 'Live Electric | Why EV?';

	return (<Page className="why-ev">
		<PageSection customClass="main-section">
			<HeroImage
				desktopSrc={sunset_road_desktop}
				mobileSrc={sunset_road_mobile}
				alt="Mountainous road going in to the sunset"
			>
				<SecondaryHeading hideHR white>
					<span className="heading-light">WestSmartEV@Scale is going further</span>
				</SecondaryHeading>
			</HeroImage>
		</PageSection>

		<PageSection customClass="intro">
			<SecondaryHeading hideHR green>
				Western Smart Regional EV Adoption and Infrastructure at Scale Projects
			</SecondaryHeading>
			<TextBlock customClass="intro-text">
				<p>
					Thanks to Rocky Mountain Power and the Department of Energy, the Western Smart Regional EV Adoption and
					Infrastructure at Scale: Electric Vehicle and Charging Community Partner Projects (WestSmartEV@Scale) was
					recharged to go even further with 12 new, exciting projects in 5 critical areas of focus and the results
					are in.
				</p>
			</TextBlock>
		</PageSection>

		<PageSection customClass="find-ride infographic">
			<div class="infographic__image">
				<picture>
					<source media="(max-width: 767px)" srcSet={west_smart_ev_infographic_mobile}/>
					<source media="(min-width: 768px)" srcSet={west_smart_ev_infographic_desktop}/>
					<img srcSet={west_smart_ev_infographic_desktop} alt="West Smart EV@Scale Infographic"/>
				</picture>
			</div>
		</PageSection>

		<PageSection customClass="juice">
			<SecondaryHeading hideHR green>
				We Added Even More High-Power Chargers
			</SecondaryHeading>
			<TextBlock>
				<p>
					WestSmartEV@Scale filled in the gaps with High-Power Chargers (DC Faster Chargers) to make EV travel
					throughout the state seamless with quicker charging options for those longer trips to our National Parks or
					the far corners of our state.
				</p>
			</TextBlock>
		</PageSection>

		<PageSection customClass="basics">
			<ImageBlock
				src={planned_charger_map}
				alt="Map of planned EV charging stations"
				imgMaxWidth="1440px"
			/>
		</PageSection>

		<PageSection customClass="where-buy">
			<SecondaryHeading hideHR green>
				WestSmartEV’s First Trip Was A Success
			</SecondaryHeading>
			<TextBlock>
				<p>
					On our first charge, from 2017-20, WestSmartEV:
					<br/>
					1) installed 79 high power chargers (DC Fast Chargers) to create two primary electric interstate highway
					corridors along I-15 and I-80, enabling rapid charging throughout the state;
					<br/>
					2) installed 1,953 L2 workplace chargers for everyday use;
					<br/>
					3) enabled 246 EV fleet deployments;
					<br/>
					4) developed Smart Mobility programs including the deployment of electric buses in multiple cities and a ride
					hailing program with telematics and energy data; and
					<br/>
					5) a built a comprehensive list of best practices for the public use.
				</p>
			</TextBlock>
			<hr className="faded-hr"/>
		</PageSection>
	</Page>);
}
